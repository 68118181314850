import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '../../../../../../bi/utils/formatDate';

import { FORMATDATETIME } from '../../../../../../constants/time';

const LABLES = {
  CURRENCY_INFO: (
    CurrencyName, CurrencyValueWithMultiplier, CurrencyValue, CurrencyMultiplierPercent, Date,
  ) => (
    `${Date}: 1 ${CurrencyName} = ${CurrencyValueWithMultiplier}р (ЦБ ${CurrencyValue} + ${CurrencyMultiplierPercent}%)`
  ),
};

const CurrencyInfoBlock = ({
  currency: {
    CurrencyName,
    CurrencyValue,
    CurrencyMultiplierPercent,
    CurrencyValueWithMultiplier,
    DateUTC,
  },
  wrapperClassName,
}) => {
  if (!CurrencyValue) return;

  const date = formatDate(DateUTC, FORMATDATETIME);

  return (
    <div className={ wrapperClassName }>
      { LABLES.CURRENCY_INFO(
        CurrencyName, CurrencyValueWithMultiplier, CurrencyValue, CurrencyMultiplierPercent, date,
      ) }
    </div>
  );
};

CurrencyInfoBlock.propTypes = {
  currency: PropTypes.object.isRequired,
  wrapperClassName: PropTypes.string,
};

CurrencyInfoBlock.defaultProps = { wrapperClassName: '' };

export { CurrencyInfoBlock };
